import { useEffect, useState } from 'react';

const initialState = {
  left: 0,
  top: 0,
  right: 0,
  bottom: 0,
  width: 0,
  height: 0,
};

const useClientRect = (wrapperDOM, callback = () => { }) => {
  const [clientRect, setClientRect] = useState(initialState);
  useEffect(() => {
    if (!wrapperDOM) return;
    if (!wrapperDOM.current) return;
    setClientRect(wrapperDOM.current.getBoundingClientRect());
    callback(clientRect);
  }, [wrapperDOM]);
  return clientRect;
};

export default useClientRect;
